import React from 'react';
import { graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import ImagesList from '../components/ImagesList';
import HeaderWithLine from '../components/HeaderWithLine';
import rightArrow from '../../static/right-arrow.svg';

const Filter = styled.span`
  list-style: none;
  color: ${({ theme }) => theme.black};
  display: block;
  text-decoration: none;
  position: relative;
  cursor: pointer;

  :hover:before {
    visibility: visible;
    width: 100%;
  }

  ::before,
  &.active ::before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.black};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &.active {
    ::before {
      content: '';
      position: absolute;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) => theme.black};
      visibility: visible;
      transition: all 0.3s ease-in-out;
      width: 100%;
    }
  }
`;

const FilterBox = styled.div`
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
`;

const FiltersWrapper = styled.div`
  text-align: center;

  &.left {
    text-align: left;
    padding-left: 2rem;
    margin-top: auto;
    margin-bottom: auto;
  }

  &.right {
    text-align: right;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 2rem;
  }
`;

const AlbumLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  display: none;

  &.main-gallery {
    display: inline-block;
  }

  ${({ theme }) => theme.mq.desktop} {
    display: inline-block;
  }
`;

const StyledSVG = styled.div`
  height: 15px;
  width: 15px;
  cursor: pointer;
  display: inline-block;
  opacity: 0.8;
  vertical-align: middle;
  padding: 0 10px;

  &.back {
    transform: rotate(180deg);
  }

  svg {
    height: 100%;
    width: auto;
    opacity: 0.8;
    margin-top: auto;
    margin-bottom: auto;
  }

  path {
    fill: black;
  }
`;

const GalleryTop = styled.div`
  margin-top: 200px;
`;

export default function GalleryAlbumPage({ data, pageContext }) {
  const { images } = data.album;

  return (
    <>
      {' '}
      <GalleryTop>
        <HeaderWithLine>{data.album.name}</HeaderWithLine>
        <FilterBox>
          <FiltersWrapper className="left">
            {pageContext.prevAlbum !== '' ? (
              <div onClick={() => navigate(`${pageContext.prevAlbum}`)}>
                <StyledSVG className="back">
                  <SVG src={rightArrow} />
                </StyledSVG>
                <AlbumLink>poprzednia galeria</AlbumLink>
              </div>
            ) : (
              ''
            )}
          </FiltersWrapper>
          <FiltersWrapper>
            <AlbumLink
              className="main-gallery"
              onClick={() => navigate(`/galeria/realizacje`)}
            >
              powrót do realizacji
            </AlbumLink>
          </FiltersWrapper>
          <FiltersWrapper className="right">
            {pageContext.nextAlbum !== '' ? (
              <div onClick={() => navigate(`${pageContext.nextAlbum}`)}>
                <AlbumLink>następna galeria</AlbumLink>
                <StyledSVG>
                  <SVG src={rightArrow} />
                </StyledSVG>
              </div>
            ) : (
              ''
            )}
          </FiltersWrapper>
        </FilterBox>
      </GalleryTop>
      {images.length > 0 ? <ImagesList images={images} /> : ''}
    </>
  );
}

export const query = graphql`
  query AlbumQuery($slug: String!) {
    album: sanityAlbum(slug: { current: { eq: $slug } }) {
      name
      id
      slug {
        current
      }
      galleryType {
        name
        hasFilters
        slug {
          current
        }
        id
      }
      images {
        asset {
          id
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
  }
`;
